import { useEffect, useState } from "react";
import {Navigate, useLocation, useNavigate, } from "react-router-dom";
import { axiosInstance } from "../../const/axiosInstance";

export function RequireAuthMain({ children }) {
  const [role, setRole] = useState("")

  const navigate = useNavigate()
  let location = useLocation();

  useEffect(() => {
    (async( ) => {
        const res = await axiosInstance.get(`/auth/user`)
        if(res.data.status){
            setRole(res.data.data.role)
        }else{
            navigate("/")
        }
    })()
  }, [])

  if (!role) { 
   return location.href = "/"
  }else if(role === "MainAdmin"){
      return children
  }else{
    return <Navigate to="/" state={{ from: location }} replace />
  }
}


export function RequireAuthMuseum({ children }) {
    const [role, setRole] = useState("")
    const navigate = useNavigate()
    let location = useLocation();
  
    useEffect(() => {
        (async( ) => {
            const res = await axiosInstance.get(`/auth/user`)
            if(res){
                setRole(res.data.data.role)
            }else{
                navigate("/")
            }
        })()
      }, [])

      if (!role) { 
        return location.href = "/"
       }else if(role === "MuseumAdmin"){
           return children
       }else{
         return <Navigate to="/" state={{ from: location }} replace />
       }
  }

