import React, { useContext } from 'react'
import { faEnvelopeOpen, faLocationDot, faSquarePhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './museum.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'

export default function MuseumContact() {

  const {selectedLang} = useContext(store)
  let lang = translate[selectedLang]

  return (
    <div className={styles['contact-div']}>
        <div className={styles['contact-part-div']}>
            <FontAwesomeIcon className={styles['contact-part-icon']} icon={faSquarePhone} />
            <p className={styles['contact-part-title']}>{lang.tr_telephone}</p>
            <p>+37415 27 27 27</p>
        </div>
        <div className={styles['contact-part-div']}>
            <FontAwesomeIcon className={styles['contact-part-icon']} icon={faEnvelopeOpen} />
            <p className={styles['contact-part-title']}>{lang.tr_mail}</p>
            <a href='mailto: info@mergelyan.com'>info@mergelyan.com</a>
        </div>
        <div className={styles['contact-part-div']}>
            <FontAwesomeIcon className={styles['contact-part-icon']} icon={faLocationDot} />
            <p className={styles['contact-part-title']}>{lang.tr_address}</p>
            <p>{lang.tr_address2}</p>
        </div>
    </div>
  )
}
