import React, { useState } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './login.module.css'
import CustomCart from '../../components/HOC/CustomCart'
import { axiosInstance } from '../../const/axiosInstance'

export default function Register() {
    const [showPass, setShowPass] = useState('password')

    const [logIn, setLogIn] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPass, setRepeatPass] = useState('')
    const [pin, setPin] = useState('')
    const [err, setErr] = useState('')

    const validation = () => {

    }

    const inputChange = (e, callback) => {
      setErr('');
      callback(e.target.value)
    }

    const register = async () => {
            if(!logIn || !password || !repeatPass || !pin){
                setErr('Լրացրե՛ք բոլոր դաշտերը')
            }else if(password.length < 8){
                setErr('Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ')
            }else if(password !== repeatPass){
                setErr('Գաղտնաբառը չի համընկնում')
            }else if(logIn.length < 3){
                setErr('Մուտքանունը պետք է պարունակի մինիմում 3 սիմվոլ')
            }
            const response = await axiosInstance.post(`/auth/singup-admin`, {username: logIn, password, adminCode: pin})
            response.data.status ? setErr(response.data.message) : setErr(response.data.message)
    }


  return (
    <div className={styles['main']}>
        <div className={styles['block-div']}>
            <CustomCart>
                <input type='text' className={styles['reg-log-input']} placeholder='Մուտքանուն' value={logIn} onChange={ e => inputChange(e, setLogIn)}/>
                <div className={styles['reg-log-input-with-eye']}>
                    <input type={showPass === 'password' ? 'password' : 'text'} placeholder='Գաղտնաբառ' value={password} onChange={e => inputChange(e, setPassword)}/>
                    {showPass === 'password' ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPass('text')}/> : <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPass('password')}/>}
                </div>
                <input type={showPass === 'password' ? 'password' : 'text'} className={styles['reg-log-input']} placeholder='Կրկնել գաղտնաբառը' value={repeatPass} onChange={ e => inputChange(e, setRepeatPass)}/>
                <input type="text" className={styles['reg-log-input']} placeholder='Ադմինիստրատորի կոդ' value={pin} onChange={ e => inputChange(e, setPin)}/>
                <button onClick={register}>Գրանցել</button>
                <div className={styles['error']}>{err}</div>
            </CustomCart>
        </div>
    </div>
  )
}
