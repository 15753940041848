import React, { useState } from 'react';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './edit.module.css'
import { BACKEND_URL } from '../../../../const/Constant';
import axios from 'axios';

export default function FileUpload ({setImage, many}) {
  const [files, setFiles] = useState([])

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleUpload =async (e) => {
    if (files.length) {
      const data = new FormData();
      for(let i = 0; i < files.length; i++){
        data.append('files', files[i]);
        data.append("filenames[]", files[i].name );
      }
      const res = await axios.post(`${BACKEND_URL}/admin/upload`, data)
      if(res.data.status){
        if(!many){
          setImage(res.data.data[0])
        } else {
          setImage(res.data.data)
        }
      }
    }
  };

  return (
    <div className={styles['file-upload']}>
        <label htmlFor="file-input" style={{cursor: "pointer"}}>
            <FontAwesomeIcon icon={faCloudArrowUp} />
        </label>
        <input
          type="file"
          multiple
          id='file-input'
          style={{display: "none"}}
          onChange={handleFileChange}
        />
      <button onClick={handleUpload}>Ներբեռնել</button>
    </div>
  );
};

