import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes } from "react-router";
import MainContent from "./components/ContentAndFooter/MainContent";
import TextEditor from "./components/TextEditor";
import Home from "./Pages/Home";
import LeadersList from './Pages/Leaders/LeadersList'
import Coworkers from './Pages/Coworkers/Coworkers'
import NewsList from './components/NewsList'
import History from './Pages/History'
import ImagesLibrary from './Pages/ImagesLibrary/ImagesLibrary'
import Museum from "./Pages/Museum/Museum";
import NewsOrDeclar from "./Pages/NewsAndDeclarations/NewsOrDeclar";
import JobList from "./Pages/Job/JobList";
import NewsOrDecPage from "./Pages/NewsAndDeclarations/NewsOrDecPage";
import JobPage from "./Pages/Job/JobPage";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import ProductPage from "./Pages/Products/ProductPage";
import { store } from './components/HOC/State';
import Expo from './Pages/Expo/Expo';
import ExpoPage from './Pages/Expo/ExpoPage';
import Rent from './Pages/Rent/Rent';
import RentItem from './Pages/Rent/RentItem';
import Register from './Pages/Admin/Register';
import Login from './Pages/Admin/Login';
import MainAdmin from './Pages/Admin/MainAdmin/MainAdmin';
import Messages from './Pages/Admin/MainAdmin/Messages';
import AdminGetItems from './Pages/Admin/MainAdmin/AdminGetItems';
import EditHistory from './Pages/Admin/MainAdmin/EditPages/EditHistory';
import EditLeaders from './Pages/Admin/MainAdmin/EditPages/EditLeaders';
import EditPartners from './Pages/Admin/MainAdmin/EditPages/EditPartners';
import EditLibrary from './Pages/Admin/MainAdmin/EditPages/EditLibrary';
import EditProducts from './Pages/Admin/MainAdmin/EditPages/EditProducts';
import EditNews from './Pages/Admin/MainAdmin/EditPages/EditNews';
import EditDeclaration from './Pages/Admin/MainAdmin/EditPages/EditDeclaration';
import EditJob from './Pages/Admin/MainAdmin/EditPages/EditJob';
import EditRent from './Pages/Admin/MainAdmin/EditPages/EditRent';
import EditExpo from './Pages/Admin/MainAdmin/EditPages/EditExpo';
import AddImage from './Pages/Admin/MainAdmin/EditPages/AddImage';
import EditNearest from './Pages/Admin/MainAdmin/EditPages/EditNearest';
import MuseumAdmin from './Pages/Admin/MuseumAdmin/MuseumAdmin';
import EditAbout from './Pages/Admin/MuseumAdmin/EditAbout';
import MuseumAdminMain from './Pages/Admin/MuseumAdmin/MuseumAdminMain';
import { RequireAuthMain, RequireAuthMuseum } from './components/HOC/RequireAuth';
import { axiosInstance } from './const/axiosInstance';
import SearchPage from './Pages/SearchPage';

export default function Main() {
  const {setShowMenu} = useContext(store)

  const [news, setNews] = useState([])

  useEffect(() => {
    (async() => {
      const res = await axiosInstance.get(`/use/news`)
      if(res.data.status){
        setNews(res.data.data)
      }else{
        setNews([])
      }
    })()
  }, [])

  return (
    <div onClick={() => setShowMenu(false)}>
        <Routes>
            <Route exact path="/" element={<MainContent/>} >
                <Route index element={<Home><NewsList news={news}/></Home>} />
                <Route path="history" element={<History><NewsList news={news}/></History>} />
                <Route path="leaders" element={<LeadersList><NewsList news={news}/></LeadersList>} />
                <Route path="partners" element={<Coworkers><NewsList news={news}/></Coworkers>} />
                <Route path="library" element={<ImagesLibrary><NewsList news={news}/></ImagesLibrary>} />
                <Route path="museum" element={<Museum />} />
                <Route path="news" element={<NewsOrDeclar category='news' />}/>
                <Route path="news/:id" element={<NewsOrDecPage category='news'><NewsList news={news}/></NewsOrDecPage>}/>
                <Route path="declaration" element={<NewsOrDeclar category='declaration'><NewsList news={news}/></NewsOrDeclar>}/>
                <Route path="declaration/:id" element={<NewsOrDecPage category='declaration'><NewsList news={news}/></NewsOrDecPage>}/>
                <Route path="career" element={<JobList><NewsList news={news}/></JobList>} />
                <Route path="career/:id" element={<JobPage><NewsList news={news}/></JobPage>}/>
                <Route path="contacts" element={<Contact><NewsList news={news}/></Contact>}/>
                <Route path="products" element={<Products><NewsList news={news}/></Products>}/>
                <Route path="products/:id" element={<ProductPage><NewsList news={news}/></ProductPage>}/>
                <Route path="expo" element={<Expo/>}/>
                <Route path="expo/:from/:id" element={<ExpoPage/>}/>
                <Route path="rent" element={<Rent/>}/>
                <Route path="rent/:id" element={<RentItem/>}/>
                <Route path="search/:searchtext" element={<SearchPage><NewsList news={news}/></SearchPage>} />
            </Route>
            <Route path="/register" element={<Register/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/dashboard" element={<RequireAuthMain><MainAdmin/></RequireAuthMain>}>
                 <Route index element={<Messages/>} /> 
                 <Route path=':category' element={<AdminGetItems/>}/>
                 <Route path='history/edit' element={<EditHistory/>}/>
                 <Route path='leaders/:id' element={<EditLeaders/>}/>
                 <Route path='partners/:id' element={<EditPartners/>}/>
                 <Route path='library/:id' element={<EditLibrary/>}/>
                 <Route path='products/:id' element={<EditProducts/>}/>
                 <Route path='news/:id' element={<EditNews/>}/>
                 <Route path='declaration/:id' element={<EditDeclaration/>}/>
                 <Route path='career/:id' element={<EditJob/>}/>
                 <Route path='rent/:id' element={<EditRent/>}/>
                 <Route path='expo/passed/:id' element={<EditExpo/>}/>
                 <Route path='expo/future/:id' element={<EditExpo/>}/>
                 <Route path='expo/nearest/:id' element={<EditNearest/>}/>
                 <Route path='leaders/add' element={<EditLeaders add='add'/>}/>
                 <Route path='partners/add' element={<EditPartners add='add'/>}/>
                 <Route path='library/add' element={<EditLibrary add='add'/>}/>
                 <Route path='products/add' element={<EditProducts add='add'/>}/>
                 <Route path='news/add' element={<EditNews add='add'/>}/>
                 <Route path='declaration/add' element={<EditDeclaration add='add'/>}/>
                 <Route path='career/add' element={<EditJob add='add'/>}/>
                 <Route path='rent/add' element={<EditRent add='add'/>}/>
                 <Route path='expo/passed/add' element={<EditExpo add='add'/>}/>
                 <Route path='expo/future/add' element={<EditExpo add='add'/>}/>
                 <Route path='expo/nearest/add' element={<EditNearest add='add'/>}/>
                 <Route path='expo/library/add' element={<AddImage/>}/>
            </Route>
            <Route path='/museum-dashboard' element={<RequireAuthMuseum><MuseumAdminMain/></RequireAuthMuseum>}>
                <Route index element={<MuseumAdmin/>} />
                <Route path="about-edit" element={<EditAbout/>} />
            </Route>
            <Route path="/text" element={<TextEditor/>} />
        </Routes>
    </div>
  )
}
