import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './product.module.css'
import { store } from '../../components/HOC/State'
import { BACKEND_URL } from '../../const/Constant'

export default function ProductItem({el}) {
  const  {selectedLang} = useContext(store)
  const id = el._id
  return (
    <Link to={`/products/${id}`}>
      <div className={styles['products-item']}>
          <img src={BACKEND_URL + el.image} alt='element-image' className={styles['products-image']}/>
          <p className={styles['products-title']}>{el[`${selectedLang}name`]}</p>
      </div>
  </Link>
  )
}
