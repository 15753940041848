import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
import SearchAd from './SearchAd'
import styles from './expoAd.module.css'
import imagestyles from './mainAdmin.module.css'
import { BACKEND_URL } from '../../../const/Constant'
import { axiosInstance } from '../../../const/axiosInstance'

export default function ExpoAdmin() {
    const [part, setPart] = useState('library')
    const [search, setSearch] = useState('')
    const [arr, setArr] = useState([])
    const [showDiv, setShowDiv] = useState(true)

    useEffect(() => {
        (async () => {
            setShowDiv(true)
            const res = await axiosInstance.get(`/use/expo/${part}`)
            if(res.data.status){
                setArr(res.data.data)
                if(part == "nearest"){
                    setShowDiv(false)
                }
            }else{
                setArr([])
            }
        }
        )()
    }, [part])

    const changeSearch = e => {
        setSearch(e.target.value)
    }

    const deleteItem = async (el) => {
        const res = await axiosInstance.delete(`/admin/expo/${part}/remove/${el._id}`)
        setArr(prev => prev.filter((item) => item._id != el._id))
        if(part == "nearest"){
            setShowDiv(true)
        }
    }

  return (
    <div>
        <div className={styles['expo-menu']}>
            <div className={part === 'library' ? `${styles['expo-title']} ${styles['choosed']}` : styles['expo-title']} onClick={() => setPart('library')}>
                Տեսադարան
            </div>
            <div className={part === 'passed' ? `${styles['expo-title']} ${styles['choosed']}` : styles['expo-title']} onClick={() => setPart('passed')}>
                Կայացած էքսպոներ
            </div>
            <div className={part === 'future' ? `${styles['expo-title']} ${styles['choosed']}` : styles['expo-title']} onClick={() => setPart('future')}>
                Ապագա էքսպոներ
            </div>
            <div className={part === 'nearest' ? `${styles['expo-title']} ${styles['choosed']}` : styles['expo-title']} onClick={() => setPart('nearest')}>
                Մոտակա էքսպո
            </div>
        </div>
        {showDiv && <div className={imagestyles['btn-search']}>
            <Link to={`${part}/add`}><button className={imagestyles['btn']}>Ավելացնել</button></Link>
            <SearchAd search={search} changeSearch={changeSearch} />
        </div>}
        <div className={part != 'library' ? imagestyles['element-div'] : imagestyles['element-images-div']}>
            {arr.filter(el => part === 'library' ? el.image?.includes(search) : part == "nearest" ? el.image?.includes("") : el.armname?.includes(search)).map((el) => 
                {return part != 'library' 
                    ?  part == "nearest" ? 
                    <div key={el._id} className={imagestyles['element-image-div']}>
                        <img src={BACKEND_URL + el.image} alt='element-image' className={imagestyles['element-image']}/>
                        <div className={imagestyles['icons']}>
                            <Link to={`${part}/${el._id}`}><FontAwesomeIcon icon={faPenToSquare} className={imagestyles['edit']}/></Link>
                            <FontAwesomeIcon icon={faXmark} className={imagestyles['remove']} onClick={() => deleteItem(el)}/>
                        </div>
                    </div>
                    :
                    <div key={el._id} className={imagestyles['element']}>
                        <p>
                            {el.armname}
                        </p>
                        <div className={imagestyles['icons']}>
                            <Link to={`${part}/${el._id}`}><FontAwesomeIcon icon={faPenToSquare} className={imagestyles['edit']}/></Link>
                            <FontAwesomeIcon icon={faXmark} className={imagestyles['remove']} onClick={() => deleteItem(el)}/>
                        </div>
                    </div>
                    :
                    <div key={el._id} className={imagestyles['element-image-div']}>
                        <img src={BACKEND_URL + el.image} alt='element-image' className={imagestyles['element-image']}/>
                        <FontAwesomeIcon icon={faXmark} className={imagestyles['remove']} onClick={() => deleteItem(el)}/>
                    </div>})
            }
        </div>
    </div>
  )
}
