import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Parser } from 'html-to-react'
import styles from './job.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function JobPage({children}) {
    const [el, setEl] = useState({})
    const [loading, setLoading] = useState(false)

    const  {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const htmlParser = new Parser()
  
    const {id} = useParams()

 
    useEffect(() =>{
        (async() => {
            setLoading(true)
            const res = await axiosInstance.get(`/use/career/${id}`)
            if(res.data.status){
              setEl(res.data.data)
            }else{
                setEl({})
            }
            setLoading(false)
        })()
      }, [])
    
  return (
        <div className={styles['job-page-main']}>
            {loading ? <Loading width={200}/> : 
            <div className={styles['job-page-info']}>
                <p className={styles['job-category']}>{lang.tr_work}</p>
                <h2 className={styles['job-page-title']}>{el[`${selectedLang}name`]}</h2>
                {htmlParser.parse(el[`${selectedLang}information`])}
            </div>}
            {children}
      </div>
  )
}
