import React from 'react'
import CustomCart from '../../../components/HOC/CustomCart'
import styles from './museumBook.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function BookingItem({el, markAsCame, remove}) {
  return (
    <div className={styles['booked-info']}>
        <div className={el.came ? `${styles['booked-info-div']} ${styles['came']}` : styles['booked-info-div']} key={el._id}>
            <div className={styles['booked-div']}><p className={styles['title']}>Անուն։</p> <p>{el.fullname}</p></div>
            <div className={styles['booked-div']}><p className={styles['title']}>Հեռախոս։</p><p>{el.phone}</p></div>
            <div className={styles['booked-div']}><p className={styles['title']}>Անձանց քանակ։</p> <p> {el.count}</p></div>
            <div className={styles['booked-div']}><p className={styles['title']}>Օր, ժամ։</p> <p> {new Date(el.date).toLocaleString()}</p></div>
            <div className={styles['icons']}>
                {!el.came ? <div><FontAwesomeIcon icon={faCheck} className={styles['check']} onClick={() => markAsCame(el._id)}/> <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el._id)}/></div>: <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el._id)}/>}
            </div>
        </div>
    </div>
  )
}
