import React from 'react'
import styles from "./imageSlides.module.css"

export default function ImagesSlide() {
    let arr = [
        "https://mergelyan.com/storage/gallery/gallery_238600746028_2045025264_60af90694795a2.54753594.jpg", 
        "https://mergelyan.com/storage/gallery/gallery_238597587545_990180996_60a66a9d37a209.13773258.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238597955828_1164701827_60a7b9e0d0d455.96324440.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238598144609_846877790_60a7b9e023cd44.95183205%20(1).jpg",
        "https://mergelyan.com/storage/gallery/gallery_238599805572_1429711628_60a66a9d5edfd6.06739316.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238600320139_1413353295_60a7b9e1588937.28510939.jpg",
        "https://mergelyan.com/storage/gallery/gallery_23860141664_820205286_60af9071838494.84207839.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238601102863_1364071182_60af9073319343.80192461.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238610912437_1847150448_1970%20%D5%A9%20.jpg",
        "https://mergelyan.com/storage/gallery/gallery_238611351163_875686954_1970%D5%A9%D5%BE%20%20.jpg",
    ]

  

return (
    <div className={styles['div-centre-all']}>
        <div className={styles['line-div']}></div>
        <div className={styles['div-centre']}>
            <div className={styles['images-div-scroll']} >
                <div className={styles['images-div']}>
                    {arr.map((el, i) => {
                        return (<div key={i}><img className={styles['images-size']} src={el} alt='slide-image' /></div>)
                    })}
                    <div><img className={styles['images-size']} src={arr[0]} alt='slide-image' /></div>
                    <div><img className={styles['images-size']} src={arr[1]} alt='slide-image'/></div>
                    <div><img className={styles['images-size']} src={arr[2]} alt='slide-image'/></div>
                    <div><img className={styles['images-size']} src={arr[3]} alt='slide-image'/></div>
                </div>
            </div>
        </div>
        <div className={styles['line-div']}></div>
    </div>
 
  )
}
