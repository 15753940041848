import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './menu.module.css'
import { store } from '../HOC/State'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function MenuItem({el, isOpen, setIsOpen, header}) {
    const {setShowMenu, setSelectedCategory, selectedLang, selectedCategory} = useContext(store)

    const {pathname} = useLocation()

    function setCategory(){
        if(isOpen === el.category){
            setIsOpen(null)
        }else{
            setIsOpen(el.category)
        }
    }

    useEffect(() => {
        if(pathname == "/"){
            setSelectedCategory(null)
        }
    }, [pathname])

    
    if(!el.underCategory){
        return (
        <Link to={el.category} onClick={() => {setShowMenu(false); setIsOpen(null); setSelectedCategory(el.category)}} style={{color: pathname == "/" ? header?.color : "inherit"}}>
            <div className={selectedCategory === el.category ? `${styles['menu-full-item-div']} ${styles['choosed']}` : styles['menu-full-item-div'] }>
                <div className={styles['menu-item-name-div']}>
                    <p>
                        {el.name[selectedLang]}
                    </p>
                </div>
            </div>
        </Link>
        )
    }else{
        return (
            <div>
                <div onClick={setCategory} style={{color: pathname == "/" ? header?.color : "inherit"}} className={isOpen === el.category ? `${styles['menu-full-item-div']} ${styles['active']}`  
                    : el.underCategory.filter(e => e.category === selectedCategory).length ? `${styles['menu-full-item-div']} ${styles['choosed']}` : styles['menu-full-item-div'] }>
                    <div className={styles['menu-item-name-div']}>
                        <p>{el.name[selectedLang]}</p>
                        {/* <p >{isOpen === el.category ? "-" : "+"}</p> */}
                        <FontAwesomeIcon className={styles['opened']} icon={faCaretDown} />
                    </div>
                </div>
                <div className={isOpen === el.category ? `${styles['underCategory-items']} ${styles['opened-undercategory']}` : styles['underCategory-items']}>
                    {isOpen === el.category ? el.underCategory.map((el,i) =><div key={el.category} ><MenuItem  el={el} isOpen={isOpen} setIsOpen={setIsOpen}/></div>) : ""}
                </div>
            </div>
        )
    }
}
