import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import TextEditor from '../../../../components/TextEditor'
import styles from './edit.module.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditNews({add}) {
    const [news, setNews] = useState({})
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const [information, setInformation] = useState('')
    const [language, setLenguage] = useState("")
    const [fromLang, setFromLang] = useState(false)

    const {id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/news/${id}`)
                if(res.data.status){
                    setNews(res.data.data)
                    setImage(res.data.data.image)
                }
            }
            setLenguage("arm")
            })()
    },[])

    useEffect(() => {
        if( name !== "" && information !== '' && information !== '<p></p>\n'){
            setNews(prev => ({...prev, [`${language}name`]: name, [`${language}information`]: information }))
        }
    }, [information, name])

    useEffect(() => {
        if(language){
            if(!add){
                setFromLang(true)
                setName(news[`${language}name`])
                setInformation(news[`${language}information`])
            }else{
                setName("")
                setInformation("")
            }
        }
    }, [language])


    const changeLanguage = async () => {
        if(name && information){
            if(language == "arm"){
               setLenguage("ru")
            }
            if(language == "ru"){
                setLenguage("eng")
            }
        }else{
            return 
        }
    }

    useEffect(() => {
        setNews(prev => {return {...prev, image}})
    }, [image])

    const addNews = async () => {
        await axiosInstance.post(`/admin/news/add`, news)
        navigate("/dashboard/news")
    }

    const editNews = async () => {
        await axiosInstance.post(`/admin/news/edit`, news)
        navigate("/dashboard/news")
    }
  
  return (
    <div className={styles['edit-part']}>
        <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
        <div className={styles['upload']}>
            <p>{add ? 'Ավելացնել' : 'Փոխել'} նորության նկարը։ </p>
            <FileUpload setImage={setImage}/>  
            <input disabled={true} placeholder='ֆայլ' value={image}/>
        </div>
        <div className={styles['info']}>
            <p>Նորության անվանում։ </p>
            <input type='text' placeholder='Նորության անվանում' value={name} onChange={(e) => setName(e.target.value)}/>
        </div>
        <div>
            <p>Նորության մասին: </p>
            <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
        </div>
        <div className={styles['btn-div']}>
            {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !name || !information || !image || information == '<p></p>\n' ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addNews : editNews}>Հաստատել</button>}
            <Link to='/dashboard/news'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}
