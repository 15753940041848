import React from 'react'
import { menu } from '../../../components/Menu/menuList'
import AdminMenuItem from './AdminMenuItem'
import styles from './mainAdmin.module.css'
import { Link, useLocation } from 'react-router-dom'

export default function SideNav() {
    const {pathname} = useLocation()
  return (
    <div className={styles['sidenav-main']}>
        <div className={styles['sidenav-title']}>Ավելացնել/Փոխել</div>
        <div className={styles['sidenav-list']}>
            <Link to={`/dashboard`} >
                <div className={styles['menu-full-item-div'] }>
                    <div className={styles['menu-item-name-div']} style={{background: pathname == '/dashboard' ? "rgba(128, 128, 128, 0.2)" : "transparent"}}>
                        <p>
                            Գլխավոր
                        </p>
                    </div>
                </div>
            </Link>
            {menu.map((el,i) => <AdminMenuItem el={el} key={i} />)}
        </div>
    </div>
  )
}
