import React, { useEffect } from 'react'
import styles from './rent.module.css'

export default function RentPromo() {

    function restart(e) {
        e.target.currentTime = 0;

        e.target.play();
    }

  return (
    <div className={styles['promo-div']}>
        <div className={styles['promo-gradient']}></div>
        <div className={styles['text']}><div>ԱՆՇԱՐԺ ԳՈՒՅՔԻ ՎԱՐՁԱԿԱԼՈՒԹՅՈՒՆ</div></div>
        <video autoPlay muted controls className={styles['promo-video']} id='promo-video-control' onEnded={(e) => restart(e)}>
            <source src='/mergelyan.mp4' type='video/mp4'/>
        </video>
    </div>
  )
}
