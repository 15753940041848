import React from 'react'
import { Outlet } from 'react-router'
import styles from './museumBook.module.css'

export default function MuseumAdminMain() {
  return (
    <div className={styles['main-museum']}>
        <Outlet/>
    </div>
  )
}
