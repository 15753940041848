import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Parser } from 'html-to-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import styles from './product.module.css'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function ProductPage({children}) {
    const [el,setEl] = useState({})
    const [loading, setLoading] = useState(false)

    const  {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const htmlParser = new Parser()

    const {id} = useParams()


  useEffect(() =>{
    (async() => {
        setLoading(true)
        const res = await axiosInstance.get(`/use/product/${id}`)
        if(res.data.status){
          setEl(res.data.data)
        }else{
            setEl({})
        }
        setLoading(false)
    })()
  }, [])
    



return (
    <div className={styles['products-page-main']}>
      {loading ? <Loading width={200}/> : 
        <div className={styles['products-page-info']}>
            <p className={styles['products-category']}>{lang.tr_product}</p>
            <img src={BACKEND_URL + el.image} alt='element-image' className={styles['products-page-image']}/>
            <h2 className={styles['products-page-title']}>{el[`${selectedLang}name`]}</h2>
            <div className={styles['products-page-created']}>
                <FontAwesomeIcon icon={faCalendarDays} />
                <p>{new Date(el.created_at).toLocaleDateString()}</p>
            </div>
            {htmlParser.parse(el[`${selectedLang}information`])}
        </div>}
      {children}
    </div>
  )
}
