import React, { useState } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomCart from '../../components/HOC/CustomCart'
import styles from './login.module.css'
import { useNavigate } from 'react-router'
import { axiosInstance } from '../../const/axiosInstance'

export default function Login() {
  const [showPass, setShowPass] = useState('password')

  const [logIn, setLogIn] = useState('')
  const [password, setPassword] = useState('')
  const [err, setErr] = useState('')

  const navigate = useNavigate()

  const inputChange = (e, callback) => {
    setErr('');
    callback(e.target.value)
  }

  const join = async () => {
        if(!logIn || !password){
          setErr('Լրացրե՛ք բոլոր դաշտերը')
          return
      }else if(password.length < 8){
          setErr('Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ')
          return
      }else if(logIn.length < 3){
          setErr('Մուտքանունը պետք է պարունակի մինիմում 3 սիմվոլ')
          return
      }
      const response = await axiosInstance.post(`/auth/login-admin`, {username: logIn, password})
      response.data.status ? response.data.data.role === "MainAdmin" ? navigate('/dashboard') :  
                             response.data.data.role === "MuseumAdmin" ? navigate('/museum-dashboard') :
                             navigate('/') :
                             setErr(response.data.message)
  }

  return (
    <div className={styles['main']}>
        <div className={styles['block-div']}>
            <CustomCart>
                <input type='text' className={styles['reg-log-input']} placeholder='Մուտքանուն' value={logIn} onChange={ e => inputChange(e, setLogIn)}/>
                <div className={styles['reg-log-input-with-eye']}>
                    <input type={showPass === 'password' ? 'password' : 'text'} placeholder='Գաղտնաբառ' value={password} onChange={e => inputChange(e, setPassword)}/>
                    {showPass === 'password' ? <FontAwesomeIcon icon={faEye} onClick={() => setShowPass('text')}/> : <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPass('password')}/>}
                </div>
                <button onClick={join}>Մուտք</button>
                <div className={styles['error']}>{err}</div>
            </CustomCart>
        </div>
    </div>
  )
}
