import React, { useContext, useEffect, useState } from 'react'
import { Parser } from 'html-to-react'
import { useParams } from 'react-router'
import styles from './expo.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function ExpoPage() {
    const [loading, setLoading] = useState(false)
    const [el, setEl] = useState({})

    const  {selectedLang} = useContext(store)

    const htmlParser = new Parser()

    const {from,id} = useParams()

    useEffect(() =>{
      (async() => {
          setLoading(true)
          const res = await axiosInstance.get(`/use/expo/${from}/${id}`)
          if(res.data.status){
            setEl(res.data.data)
          }else{
            setEl({})
          }
          setLoading(false)
      })()
    }, [])



  return (
    <div className={styles['expo-page-main']}>
        {loading ? <Loading width={200}/> : 
        <div className={styles['expo-page-info']}>
            <img src={BACKEND_URL + el.image} alt='element-image' className={styles['expo-page-image']}/>
            <h2 className={styles['expo-page-title']}>{el[`${selectedLang}name`]}</h2>
            <div className={styles['expo-page-created']}>
                <FontAwesomeIcon icon={faCalendarDays} />
                <p>{new Date(el.date).toLocaleString()}</p>
            </div>
            {htmlParser.parse(el[`${selectedLang}information`])}
        </div>
        }
    </div>
  )
}
