import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import TextEditor from '../../../../components/TextEditor'
import styles from './edit.module.css'
import { DtPicker } from 'react-calendar-datetime-picker'
import './calendar-edit.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditExpo({add}) {
    const lock = useLocation().pathname.split("/")
    const part = lock[lock.length-2]

    const [expo, setExpo] = useState({})
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const [date, setDate] = useState() 
    const [information, setInformation] = useState('')
    const [language, setLenguage] = useState("")
    const [fromLang, setFromLang] = useState(false)

    const {id} = useParams()
    const navigate = useNavigate()

    const minDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    }

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/expo/${part}/${id}`)
                if(res.data.status){
                    setExpo(res.data.data)
                    setImage(res.data.data.image)
                    setDate({
                            year: new Date(res.data.data.date).getFullYear(),
                            month: new Date(res.data.data.date).getMonth() + 1,
                            day: new Date(res.data.data.date).getDate(),
                            hour: new Date(res.data.data.date).getHours(),
                            minute: new Date(res.data.data.date).getMinutes()
                        })
                }
            }
            setLenguage("arm")
            })()
    },[])

    useEffect(() => {
        if( name !== "" && information !== '' && information !== '<p></p>\n'){
            setExpo(prev => ({...prev, [`${language}name`]: name, [`${language}information`]: information }))
        }
    }, [information, name])

    useEffect(() => {
        if(language){
            if(!add){
                setFromLang(true)
                setName(expo[`${language}name`])
                setInformation(expo[`${language}information`])
            }else{
                setName("")
                setInformation("")
            }
        }
    }, [language])

    function formatDate(date) {
        const { day, month, year, hour, minute } = date;
        const formattedDate = new Date(year, month - 1, day, hour, minute);
        
        return formattedDate
    }

    useEffect(() => {
        setExpo(prev => {return {...prev, image}})
    }, [image])
    

    useEffect(() => {
        if(date){
            setExpo(prev => {return {...prev, date: formatDate(date)}})
        }
    }, [date])

    const changeLanguage = async () => {
        if(name && information){
            if(language == "arm"){
               setLenguage("ru")
            }
            if(language == "ru"){
                setLenguage("eng")
            }
        }else{
            return 
        }
    }
    const addExpo = async () => {
        await axiosInstance.post(`/admin/expo/${part}/add`, expo)
        navigate("/dashboard/expo")
    }

    const editExpo = async () => {
        await axiosInstance.post(`/admin/expo/${part}/edit`, expo)
        navigate("/dashboard/expo")
    }

  return (
    <div className={styles['edit-part']}>
        <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
        <div className={styles['upload']}>
            <p>{add ? 'Ավելացնել' : 'Փոխել'} էքսպոյի նկարը։ </p>
            <FileUpload setImage={setImage}/>  
            <input disabled={true} placeholder='ֆայլ' value={image}/>
        </div>
        <div className={styles['info']}>
            <p>Էքսպոյի անվանում։ </p>
            <input type='text' placeholder='Էքսպոյի անվանում' value={name} onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Էքսպոյի ամսաթիվ։ </p>
            <DtPicker 
                className='calendar-edit' 
                onChange={setDate}  
                value={date} 
                initValue={add ? {...minDate, hour: new Date().getHours(), minute: new Date().getMinutes()} : 
                {
                    year: new Date(expo.date).getFullYear(),
                    month: new Date(expo.date).getMonth() + 1,
                    day: new Date(expo.date).getDate(),
                    hour: new Date(expo.date).getHours(),
                    minute: new Date(expo.date).getMinutes()
                }
            } 
                local='en' 
                withTime={true} 
                showTimeInput={true} 
                showWeekend={true} 
                minDate={part == 'future' ? minDate : ""}
                placeholder='Ընտրել օրն ու ժամը'/>
        </div>
        <div>
            <p>Էքսպոյի մասին: </p>
            <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
        </div>
        <div className={styles['btn-div']}>
            {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !name || !information || !image || !date ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addExpo : editExpo}>Հաստատել</button>}
            <Link to='/dashboard/expo'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}

