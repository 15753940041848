import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import Search from './Search'
import Lang from './Lang/Lang'

import styles from './header.module.css'
import { store } from '../HOC/State';
import { translate } from '../../Pages/translate';
import Menu from '../Menu/Menu';

export default function HeaderSide({header}) {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const {pathname} = useLocation()

  return (
    <div className={pathname == "/" ? `${styles['header-div']} ${styles['home-header-div']}` : styles['header-div']} style={header}>
        <Link to='/' className={styles['logo-div']} style={{color: header.color}}>
            <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
            <div className={styles['name-div']}>
                <h1 className={styles['name-h']}>{lang.tr_shortName}</h1>
            </div>
        </Link>
        <Menu header={header}/>
        <div className={styles['search-lang-end']}>
            <div className={styles['search-lang-div']}>
                <Search header={header}/>
                <Lang header={header}/>
            </div>
        </div>
    </div>
  )
}
