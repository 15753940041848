import React from 'react'
import style from './loading.module.css'

export default function Loading({width}) {
  return (
    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <div className={style['loading-div']} >
          <img src='/Loading.webp' alt='loading' style={{width: width + "px"}}/>
          <p>Loading...</p>
      </div>
    </div>
  )
}
