import React, { useContext } from 'react'
import CustomCart from '../../components/HOC/CustomCart'
import { Parser } from 'html-to-react'
import styles from './leaders.module.css'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'

export default function Leader({el}) {
    const  {selectedLang} = useContext(store)

    const htmlParser = new Parser()
    
  return (
    
    <div className={styles['each-leader-div']}>
        <CustomCart>
            <div className={styles['leader']}>
                <div className={styles['leader-media-info']}>
                    <img  className={styles['leader-image']} src={BACKEND_URL + el.image} alt='person-image' />
                    <p>{el[`${selectedLang}name`]}</p>
                    <p>{el.terms}</p>
                </div>
                <div className={styles['leader-main-info']}>
                    {htmlParser.parse(el[`${selectedLang}information`])}
                </div>
            </div>
        </CustomCart>
    </div>
    
  )
}
