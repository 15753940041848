import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import styles from './museum.module.css'

export default function MuseumSlide() {
    let [showText, setShowText] = useState(false)

    let arr = [
      "/1.jpg", "/2.jpg" , "/3.jpg" , "/4.jpg" , "/1.jpg"    
    ]
    
    const hideText = () => setTimeout(() => setShowText(false), 3500)

    useEffect(() => {
      setTimeout((setShowText(true), 1000))
      setTimeout(() => setShowText(false), 4000)
      let picker = setInterval(slideRight, 5000)

      return () => clearInterval(picker)
    },[])


    const slideLeft = () => {
      setShowText(false)
       let slide = document.getElementById('museum-slide')
       if(slide.scrollLeft !== 0){
         slide.scrollTo({
            left: slide.scrollLeft - window.innerWidth - ((slide.scrollLeft - window.innerWidth) % window.innerWidth),
            behavior: "smooth"
         })
         if(slide.scrollLeft <= window.innerWidth){
            setShowText(true)
            hideText()
         }
       } else {
        slide.scrollTo({
          left: slide.scrollWidth,
          behavior: "auto"
        })
       }
    }

    const slideRight = () => {
      setShowText(false)
      let slide = document.getElementById('museum-slide')
      if(slide.scrollLeft + window.innerWidth < slide.scrollWidth){
        slide.scrollTo({
           left: slide.scrollLeft + window.innerWidth - ((slide.scrollLeft - window.innerWidth) % window.innerWidth),
           behavior: "smooth"
        })
      } else {
        setShowText(true)
        hideText()
       slide.scrollTo({
         left: 0,
         behavior: "auto"
       })
      }
   }

  return (
    <div className={styles['parent-div']}>
      <div  className={styles['image-slide-main']} id='museum-slide'>
          <div onClick={slideLeft} className={`${styles['left']} ${styles['control-btn']}`}><FontAwesomeIcon className={styles['control-icon']} icon={faAngleLeft} /></div>
          <div className={styles['image-div']}>
              {arr.map((el,i) => (<img src={el} alt='museum-image' />))}
              <div className={showText ? `${styles['text']} ${styles['show']}` : styles['text']}><div>ՄԵՐԳԵԼՅԱՆ ԹԱՆԳԱՐԱՆ</div></div>
          </div>
          <div onClick={slideRight} className={`${styles['right']} ${styles['control-btn']}`}><FontAwesomeIcon className={styles['control-icon']} icon={faAngleRight} /></div>
      </div>
    </div>
  )
}
