import React, { useContext, useEffect, useState } from 'react'
import ProductItem from './ProductItem'
import styles from './product.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function Products({children}) {
    const [arr, setArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateed, setUpdated] = useState(false)
    const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
    const limit = 20

    let lang = translate[selectedLang]

    useEffect(() => {
      setOffset(0)
      setUpdated(true)
    }, [])

    useEffect(() =>{
      (async() => {
        if(updateed){
          setLoading(true)
          const res = await axiosInstance.get(`/use/products?offset=${offset}&limit=${limit}`)
          if(res.data.status){
            setArr([...arr,...res.data.data])
            if(res.data.data.length == 20){
              setOffsetUpdated(false)
            }
          }else{
            setArr([...arr])
          }
          setLoading(false)
        }
      })()
    }, [offset, updateed])

  return (
    <div className={styles['products-main']}> 
        <div className={styles['products-size']}>    
            <div className={styles['products-category']}>{lang.tr_product}</div> 
            <div className={styles['products-list']}>
              {arr.length ? (
                arr.map(el => (
                    <ProductItem el={el} key={el._id}/>
                ))
              ):(
                !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null 
              )}
                {loading ? <Loading width={200}/> : ""}
            </div>
        </div>  
        {children}
    </div>
  )
}
