import React, { useContext, useEffect, useState } from 'react'
import { Parser } from 'html-to-react'
import styles from './museum.module.css'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function MuseumInfo() {
    const [about, setAbout] = useState('')
    const [loading, setLoading] = useState(false)

    const {selectedLang} = useContext(store)

    const htmlParser = new Parser()

    useEffect(() => {
      (async () => {
          setLoading(true)
          const res = await axiosInstance.get(`/use/about-museum`)
              if(res.data.status){
                  setAbout(res.data.data)
              }else{
                  setAbout('')
              }
              setLoading(false)
      })()
  }, [])

  return (
    <div className={styles['history-div']}>
      {loading ? <Loading width={200}/> :
        <div>{htmlParser.parse(about[`${selectedLang}text`])}</div>
      }
    </div>
  )
}
