import React, { useEffect, useState } from 'react';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './edit.module.css'
import { BACKEND_URL } from '../../../../const/Constant';
import axios from 'axios';

export default function FileUploadText () {
  const [files, setFiles] = useState([])
  const [image, setImage] = useState("")

  const handleUpload =async (e) => {
      const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append("filename", e.target.files[0]?.name );

        const res = await axios.post(`${BACKEND_URL}/admin/upload-any`, data)
        if(res){
          setImage(`${BACKEND_URL}/uploads/` + res.data.data)
    }
  };

  const copy = (e) => {
    navigator.clipboard.writeText((e.target.innerText).toLowerCase())
  }

  useEffect(()=>{
    if(image){
      setFiles(prev => [...prev, image])
      const links = document.getElementById('files-links')
      setTimeout(() => links.scrollTop = links.scrollHeight, 200)
    }
  }, [image])

  return (
    <div>
      <div className={styles['file-upload-text']}>
          <label htmlFor="file-input-text" style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faCloudArrowUp} />
          </label>
          <input
            type="file"
            id='file-input-text'
            style={{display: "none"}}
            onChange={(e) => {handleUpload(e)}}
          />
          <div className={styles['files-div']} id='files-links'>
              {
                files.map((el, i) => (
                  <p key={i} className={styles['files-div-text']} onClick={(e) => copy(e)}>{el}</p>
                ))
              }
          </div>
      </div>
    </div>
  );
};

