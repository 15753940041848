import React, { useContext, useState } from 'react'
import { DtPicker } from 'react-calendar-datetime-picker'
import styles from './museum.module.css'
import 'react-calendar-datetime-picker/dist/style.css'
import './calendar.css'
import { translate } from '../translate'
import { store } from '../../components/HOC/State'
import { useEffect } from 'react'
import { axiosInstance } from '../../const/axiosInstance'



export default function Booking() {
    const [expo, setExpo] = useState({})
    const [date, setDate] = useState(new Date()) 
    const [name, setName] = useState("")
    const [phone, setPhone] = useState('')
    const [count, setCount] = useState('')

    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    useEffect(() => {
        if(date){
            setExpo(prev => {return {...prev, date: formatDate(date)}})
        }
    }, [date])

    useEffect(() => {
        setExpo(prev => {return {...prev, fullname : name}})
    }, [name])

    useEffect(() => {
        setExpo(prev => {return {...prev, phone}})
    }, [phone])

    useEffect(() => {
        setExpo(prev => {return {...prev, count}})
    }, [count])

    function formatDate(date) {
        const { day, month, year, hour, minute } = date;
        const formattedDate = new Date(year, month - 1, day, hour, minute);
        
        return formattedDate
    }

    const minDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    }

    const send = async () => {
        if(!name || !phone || !date || !count){
            return
        }
        const res = await axiosInstance.post(`/use/booking`, expo)
        if(res.data.status){
            setDate({...minDate, hour: new Date().getHours(), minute: new Date().getMinutes()})
            setName("")
            setPhone("")
            setCount("")
        }
    }

  return (
    <div className={styles['booking-div']}>
        <div className={styles['date']}>
             <p>{lang.tr_date}</p>
            <DtPicker 
                className='calendar' 
                onChange={setDate} 
                value={date} 
                initValue={{...minDate, hour: new Date().getHours(), minute: new Date().getMinutes()}} 
                local='en' 
                withTime={true} 
                showTimeInput={true} 
                showWeekend={true} 
                minDate={minDate}
                placeholder={lang.tr_date}/>
        </div>
        <div className={styles['date']}>
            <p>{lang.tr_fullName}</p>
            <input className={styles['name-input']} type='text' placeholder={lang.tr_fullName} value={name} onChange={e => setName(e.target.value)}/>
        </div>
        <div className={styles['date']}>
            <p>{lang.tr_telephone}</p>
            <input className={styles['name-input']} type='number' placeholder={`${lang.tr_telephone} (0yy-XX-XX-XX)`} value={phone} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className={styles['date']}>
            <p>{lang.tr_persons}</p>
            <input className={styles['name-input']} type='number' placeholder={lang.tr_persons} value={count} onChange={e => setCount(e.target.value)}/>
        </div>
        <button className={styles['book-btn']} onClick={send}>{lang.tr_sendBook}</button>
           
    </div>
  )
}
