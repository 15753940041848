import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './textEditor.css'
import FileUploadText from '../Pages/Admin/MainAdmin/EditPages/FileUploadText';

export default function TextEditor({information, setInformation, fromLang, setFromLang}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if(!information){
      setEditorState(EditorState.createEmpty())
    }else{
      if(fromLang){
        const contentBlock = htmlToDraft(information)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)))
      }
    }
  }, [information])

  useEffect(() => {
    if(!fromLang){
      setInformation(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }else{
      setFromLang(false)
    }
  }, [editorState])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

 

  return (
    <div className='editor'>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        placeholder="Մուտքագրե՛լ տեքստը..."
        toolbarCustomButtons={[<FileUploadText/>]}
        toolbar={{
          emoji: {
            emojis: [
              '😀','😃','😄','😁','😆','😅','🤣','😂','🙂','🙃','😉',
              '😊','😇','🥰','😍','🤩','😘','😗','☺️','😚','😙','😋',
              '😛','😜','🤪','😝','🤑','🤗','🤭','🤫','🤔','🤐','🤨'
              ,'😐️','😑','😶','😏','😒','🙄','😬','🤥','😌','😔','😪'
              ,'😮‍','💨','🤤','😴','😷','🤒','🤕','🤢','🤮','🤧','🥵',
              '🥶','😶‍','🌫️','🥴','😵‍','💫','😵','🤯','🤠','🥳','😎'
              ,'🤓','🧐','😕','😟','🙁','☹️','😮','😯','😲','😳','🥺','😦'
              ,'😧','😨','😰','😥','😢','😭','😱','😖','😣','😞',
              '😓','😩','😫','🥱','😤','😡','😠','🤬','😈','👿','👺','👻'
              ,'👽️','👾','🤖','😺','😸','😹','😻','😼','😽','🙀','😿','😾'
              ,'🙈','🙉','🙊','👋','🤚','🖐️','✋','🖖','👌','🤏','✌️','🤞','🤟'
              ,'🤘','🤙','👈️','👉️','👆️','👇️','☝️','👍️','👎️','✊','👊','🤛','🤜',
              '👏','🙌','👐','🤲','🤝','🙏','✍️','💅','🤳','💪','🦷','🦴','👀',
              '👁️','👅','👄','💋','👶','🧒','👦','👧','🧑','👨','👩','🧔','🧔‍♀️',
              '🧔‍♂️','🧑','👨‍','🦰','👩‍','🦰','🧑','👨‍','🦱','👩‍','🦱','🧑','👨‍',
              '🦳','👩‍','🦳','🧑','👨‍','🦲','👩‍','🦲','👱','👱‍♂️','👱‍♀️','🧓','👴',
              '👵','🙍','🙍‍♂️','🙍‍♀️','🙎','🙎‍♂️','🙎‍♀️','🙅','🙅‍♂️','🙅‍♀️','🙆','🙆‍♂️',
              '🙆‍♀️','💁','💁‍♂️','💁‍♀️','🙋','🙋‍♂️','🙋‍♀️','🧏','🧏‍♂️','🧏‍♀️','🙇','🙇‍♂️','🙇‍♀️','🤦',
              '🤦‍♂️','🤦‍♀️','🤷','🤷‍♂️','🤷‍♀️','💘','💝','💖','💗','💓','💞','💕','💟','❣️',
              '💔','❤️','🧡','💛','💚','💙','💜','🤎','🖤','🤍','❤️‍','🔥','❤️‍','🩹','💯',
            ],
          },
          embedded:{
            icon: '/video-solid.svg'
          }
        }}
      />
    </div>
  );
}
