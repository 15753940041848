import React, { useContext, useEffect } from 'react'
import NewsItem from './NewsItem'
import styles from './news.module.css'
import { store } from './HOC/State'
import { translate } from '../Pages/translate'

export default function NewsList({news}) {
  const {selectedLang} = useContext(store)
  let lang = translate[selectedLang]

    const slideDown = () => {
        let slide = document.getElementById('news-scroll')
        if(slide.scrollTop + window.innerHeight - 173 < slide.scrollHeight){
          slide.scrollTo({
             top: slide.scrollTop + window.innerHeight - 173,
             behavior: "smooth"
          })
        } else {
         slide.scrollTo({
           top: 0,
           behavior: "auto"
         })
        }
     }

     useEffect(() => {
        let picker = setInterval(() => {
            slideDown()
        }, 7000)
        return () => clearInterval(picker)
     }, [])

  return (
    <div className={styles['news-list-div']}> 
        <p className={styles['news-list-h2']}>{lang.tr_news}</p>
        <div className={styles['news-list']} id='news-scroll'>
            {news.map((el)=> 
                <NewsItem key={el._id} el={el} />
            )}
        </div>
    </div>
  )
}
