import React, { useContext, useEffect, useState } from 'react'
import styles from './Products/product.module.css'
import { Link, useParams } from 'react-router-dom'
import { store } from '../components/HOC/State'
import { translate } from './translate'
import { axiosInstance } from '../const/axiosInstance'
import Loading from '../components/Loading'
import { BACKEND_URL } from '../const/Constant'

export default function SearchPage({children}) {
  const [arr, setArr] = useState([])
  const [loading, setLoading] = useState(false)
  const { selectedLang} = useContext(store)

  const {searchtext} = useParams()
  let lang = translate[selectedLang]


  useEffect(() =>{
    (async() => {
        setLoading(true)
        const res = await axiosInstance.get(`/use/search/${searchtext}`)
        if(res.data.status){
          setArr(res.data.data)
        }else{
          setArr([])
        }
        setLoading(false)
    })()
  }, [searchtext])

  return (
    <div className={styles['products-main']}> 
    <div className={styles['products-size']}>    
      <div className={styles['products-category']}>{lang.tr_search}</div> 
        <div className={styles['products-list']}>
          {arr.length ? 
              arr.map(el => (
                <Link to={`/${el.category}/${el._id}`} key={el._id}>
                    <div className={styles['products-item']}>
                        <img src={BACKEND_URL + el.image} alt='element-image' className={styles['products-image']}/>
                        <p className={styles['products-title']}>{el[`${selectedLang}name`]}</p>
                    </div>
                </Link>
              )) :
              !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null

          }
            {loading ? <Loading width={200}/> : ""}
        </div>
    </div>  
    {children}
</div>
  )
}
