import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import TextEditor from '../../../../components/TextEditor'
import styles from './edit.module.css'
import { BACKEND_URL } from '../../../../const/Constant'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditRent({add}) {
    const [rent, setRent] = useState({})
    const [images, setImages] = useState([])
    const [area, setArea] = useState(0)
    const [rooms, setRooms] = useState(0)
    const [forniture, setForniture] = useState('')
    const [renovation, setRenovation] = useState('')
    const [price, setPrice] = useState(0)
    const [information, setInformation] = useState('')
    const [language, setLenguage] = useState("")
    const [fromLang, setFromLang] = useState(false)

    const {id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/rent/${id}`)
                if(res.data.status){
                    setRent(res.data.data)
                    setImages(res.data.data.images)
                    setArea(res.data.data.area)
                    setRooms(res.data.data.rooms)
                    setPrice(res.data.data.price)
                }
            }
            setLenguage("arm")
            })()
    },[])

    useEffect(() => {
        if( forniture !== "" && renovation !== '' && information !== '' && information !== '<p></p>\n'){
            setRent(prev => ({...prev, [`${language}forniture`]: forniture, [`${language}information`]: information, [`${language}renovation`]: renovation }))
        }
    }, [information, forniture, renovation])


    const deleteImage = image => {
        setImages(prev => prev.filter(el => el != image))
    }

    const addImage = (image) => {
        setImages(prev => [...prev, ...image])
    }

    useEffect(() => {
        if(language){
            if(!add){
                setFromLang(true)
                setForniture(rent[`${language}forniture`])
                setRenovation(rent[`${language}renovation`])
                setInformation(rent[`${language}information`])
            }else{
                setForniture("")
                setRenovation("")
                setInformation("")
            }
        }
    }, [language])

    
    const changeLanguage = async () => {
        if(forniture && information && renovation){
            if(language == "arm"){
                setLenguage("ru")
            }
            if(language == "ru"){
                setLenguage("eng")
            }
        }else{
            return 
        }
    }

    useEffect(() => {
        setRent(prev => {return {...prev, images}})
    }, [images.length])

    useEffect(() => {
        setRent(prev => {return {...prev, area}})
    }, [area])

    useEffect(() => {
        setRent(prev => {return {...prev, rooms}})
    }, [rooms])

    useEffect(() => {
        setRent(prev => {return {...prev, price}})
    }, [price])

    const addRent = async () => {
        await axiosInstance.post(`/admin/rent/add`, rent)
        navigate("/dashboard/rent")
    }

    const editRent = async () => {
        await axiosInstance.post(`/admin/rent/edit`, rent)
        navigate("/dashboard/rent")
    }

  return (
    <div className={styles['edit-part']}>
        <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
        <div className={styles['upload-images-div']}>
            <div className={styles['upload']}>
                <p>{add ? 'Ավելացնել' : 'Փոխել'} տարածքի նկարները։ </p>
                <FileUpload setImage={addImage} many={true}/>  
                <input disabled={true} placeholder='ֆայլ' value={`${images.length} հատ`}/>
            </div>
            <div className={styles['images-div']}>
                {images.map((el,i) => (
                    <div key={i} onClick={() => deleteImage(el)}>
                        <img src={BACKEND_URL + el} alt='place-image'/>
                    </div>
                ))}
            </div>
          
        </div>
        <div className={styles['info']}>
            <p>Մակերես։ </p>
            <input type='number' placeholder='Մակերես' value={area} onChange={(e) => setArea(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Սենյակների քանակ։ </p>
            <input type='number' placeholder='Սենյակների քանակ' value={rooms} onChange={(e) =>setRooms(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Կահույք։ </p>
            <input type='text' placeholder='Կահույք' value={forniture} onChange={(e) => setForniture(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Վերանորոգում։ </p>
            <input type='text' placeholder='Վերանորոգում' value={renovation} onChange={(e) => setRenovation(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Արժեք։ </p>
            <input type='number' placeholder='Արժեք' value={price} onChange={(e) => setPrice(e.target.value)}/>
        </div>
        <div>
            <p>Տարածքի մասին: </p>
            <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
        </div>
        <div className={styles['btn-div']}>
            {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !area || !information || !rooms || !forniture || !renovation || !price || !images.length ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addRent : editRent}>Հաստատել</button>}
            <Link to='/dashboard/rent'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}

