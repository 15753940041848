import React, { useContext, useEffect, useState } from 'react'
import { Parser } from 'html-to-react'
import RentImages from './RentImages'
import { useParams } from 'react-router'
import styles from './rent.module.css'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function RentItem() {
    const [el, setEl] = useState({})
    const [loading, setLoading] = useState(false)

    const  {selectedLang} = useContext(store)
    
    const htmlParser = new Parser()

    const {id} = useParams()

    useEffect(() =>{
        (async() => {
            setLoading(true)
            const res = await axiosInstance.get(`/use/rent/${id}`)
            if(res.data.status){
                setEl(res.data.data)
            }else{
                setEl({})
            }
            setLoading(false)
        })()
      }, [])
  

  return (
    <div className={styles['rent-item-main']}>
        {loading ? <Loading width={200}/> :
        <div className={styles['rent-item-div']}>
            <RentImages images={el.images}/>
            <div className={styles['rent-item-information']}>
                <div className={styles['each-info']}>
                    <p>Հասցե</p>
                    <p>ք․ Երևան, Հ.Հակոբյան 3</p>
                </div>
                <div className={styles['each-info']}>
                    <p>Մակերես</p>
                    <p>{el.area}</p>
                </div>
                <div className={styles['each-info']}>
                    <p>Սենյակների քանակ</p>
                    <p>{el.rooms}</p>
                </div>
                <div className={styles['each-info']}>
                    <p>Կահույք</p>
                    <p>{el[`${selectedLang}forniture`]}</p>
                </div>
                <div className={styles['each-info']}>
                    <p>Վերանորոգում</p>
                    <p>{el[`${selectedLang}renovation`]}</p>
                </div>
                <div className={styles['each-info']}>
                    <p>Արժեք</p>
                    <p>{el.price}֏</p>
                </div>
                <div className={styles['other-info']}>
                    <p className={styles['other-title']}>Լրացուցիչ</p>
                    {htmlParser.parse(el[`${selectedLang}information`])}
                </div>
            </div>
        </div>}
    </div>
  )
}
