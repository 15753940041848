import React, { useContext } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'


export default function Home({children}) {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]
  return (
    <div>
      <div className={styles['home-div']}>
        <div>
            <img src='/main.gif' alt='main'/>
            <div className={styles['name-h']}>
              <h1 >{lang.tr_nameOfOrganization}</h1>
              <h1 >{lang.tr_nameOfOrganization2}</h1>
            </div>
        </div>
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
      <ImagesSlide />
    </div>
    
  )
}
