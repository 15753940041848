import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './job.module.css'
import { store } from '../../components/HOC/State'

export default function JobItem({el}) {
  const  {selectedLang} = useContext(store)
  const id = el._id
  return (
    <Link to={`/career/${id}`} className={styles['job-item']}>
            <p>{el[`${selectedLang}name`]}</p>
            <div className={styles['see-job']}>Ծանոթանալ աշխատանքին</div>
    </Link>
  )
}
