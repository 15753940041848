import React, { useContext, useState } from 'react'
import MuseumSlide from './MuseumSlide'
import MuseumInfo from './MuseumInfo'
import Booking from './Booking'
import MuseumContact from './MuseumContact'
import styles from './museum.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'

export default function Museum() {
    const [selected, setSelected] = useState('About')

    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

 
  return (
    <div className={styles['hole-museum']}>
        <div>
            <MuseumSlide />
        </div>
        <div className={styles['undermenues']}>
            <div className={selected === 'About' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('About')}>
               <p>{lang.tr_aboutMuseum}</p>
            </div>
            <div className={selected === 'Booking' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('Booking')}>
                <p>{lang.tr_Book}</p>
            </div>
            <div className={selected === 'Contact' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('Contact')}>
                <p>{lang.tr_contacts}</p>
            </div>
        </div>
        {selected === 'About' ? <MuseumInfo/> : selected === 'Booking' ? <Booking/> : <MuseumContact/>}
    </div>
  )
}
