import React, { useContext, useEffect, useState } from 'react'
import JobItem from './JobItem'
import styles from './job.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function JobList({children}) {
    const [arr, setArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateed, setUpdated] = useState(false)
    const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
    const limit = 20
    
    let lang = translate[selectedLang]
    
      useEffect(() => {
        setOffset(0)
        setUpdated(true)
      }, [])

      useEffect(() =>{
        (async() => {
          if(updateed){
            setLoading(true)
            const res = await axiosInstance.get(`/use/career?offset=${offset}&limit=${limit}`)
            if(res.data.status){
              setArr([...arr,...res.data.data])
              if(res.data.data.length == 20){
                setOffsetUpdated(false)
              }
            }else{
              setArr([...arr])
            }
            setLoading(false)
          }
        })()
      }, [offset, updateed])

  return (
    <div className={styles['job-main']}>
        <div className={styles['job-list']}>
            <p className={styles['job-category']}>{lang.tr_work}</p>
            {arr.length ? 
              <div>
                {arr.map(el=> <JobItem key={el._id} el={el}/>)}
                {loading ? <Loading width={200}/> : ""}
              </div>
            :
              !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null
            }
            
        </div>
        {children}
    </div>
  )
}
