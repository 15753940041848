import React, { useContext } from 'react'
import CustomCart from '../../components/HOC/CustomCart'
import { Parser } from 'html-to-react'
import styles from './coworkers.module.css'
import { store } from '../../components/HOC/State'
import { BACKEND_URL } from '../../const/Constant'

export default function CoworkersItem({el}) {
  const  {selectedLang} = useContext(store)

  const htmlParser = new Parser()
  return (
    <div className={styles['each-coworker-div']}>
        <CustomCart>
            <div className={styles['coworker']}>
                <div className={styles['coworker-media-info']}>
                    <img  className={styles['coworker-image']} src={BACKEND_URL + el.image} alt='person-image' />
                    <p>{el[`${selectedLang}name`]}</p>
                </div>
                <div className={styles['coworker-main-info']}>
                    {htmlParser.parse(el[`${selectedLang}information`])}
                </div>
            </div>
        </CustomCart>
    </div>
  )
}
