import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './expo.module.css'
import { store } from '../../components/HOC/State'
import { BACKEND_URL } from '../../const/Constant'

export default function ExpoItem({el, from}) {
  const  {selectedLang} = useContext(store)
  const id = el._id
  return (
    <Link to={`/expo/${from}/${id}`}>
      <div className={styles['expo-item']}>
          <img src={BACKEND_URL + el.image} alt='element-image' className={styles['expo-item-image']}/>
          <p className={styles['expo-title']}>{el[`${selectedLang}name`]}</p>
      </div>
    </Link>
  )
}
