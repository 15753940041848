import React, { useEffect, useState } from 'react'
import TextEditor from '../../../components/TextEditor'
import { Link, useNavigate } from 'react-router-dom'
import styles from '../MainAdmin/EditPages/edit.module.css'
import { axiosInstance } from '../../../const/axiosInstance'

export default function EditAbout() {
  const [about, setAbout] = useState({})
  const [information, setInformation] = useState('')
  const [language, setLenguage] = useState("")
  const [fromLang, setFromLang] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
            const res = await axiosInstance.get(`/use/about-museum`)
            if(res.data.status){
                setAbout(res.data.data)
            }
        setLenguage("arm")
        })()
},[])

useEffect(() => {
  if( information !== '' && information !== '<p></p>\n'){
      setAbout(prev => ({...prev,[`${language}text`]: information }))
  }
}, [information])

useEffect(() => {
  if(language){
    setFromLang(true)
    setInformation(about[`${language}text`])
  }
}, [language])

const changeLanguage = async () => {
  if(information !== '<p></p>\n'){
      if(language == "arm"){
         setLenguage("ru")
      }
      if(language == "ru"){
          setLenguage("eng")
      }
  }else{
      return 
  }
}


const editHistory = async () => {
  await axiosInstance.post(`/admin/about-museum`, about)
  navigate("/museum-dashboard")
}

  return (
    <div className={styles['edit-history']}>
      <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
        <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
        <div className={styles['btn-div']}>
            {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !information || information == '<p></p>\n' ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={editHistory}>Հաստատել</button>}
            <Link to='/museum-dashboard'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}
