import React, { useEffect, useState } from 'react'
import BookingItem from './BookingItem'
import styles from "./museumBook.module.css"
import SearchAd from '../MainAdmin/SearchAd'
import { axiosInstance } from '../../../const/axiosInstance'

export default function Bookings() {
    const [arr, setArr] = useState([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        (async () => {
            const res = await axiosInstance.get(`/admin/bookings`)
            if(res.data.status){
                setArr(res.data.data)
            }else{
                setArr([])
            }
        }
        )()
    }, [])

    const changeSearch = e => {
        setSearch(e.target.value)
    }


    const markAsCame = async (id) => {
        await axiosInstance.post(`/admin/bookings`, {id})
        setArr(prev => prev.map(el => el._id == id ? {...el,came:true} : el))
    }

    const remove = async (id) => {
        await axiosInstance.delete(`/admin/bookings/${id}`)
        setArr(prev => prev.filter(item => item._id != id))
    }

  return (
    <div>
        <div className={styles['search-div']}>
            <SearchAd search={search} changeSearch={changeSearch}/>
        </div>
        <div className={styles['booking-list']}>
            {!arr.length ? <p>Ամրագրումներ չկան</p> : arr.filter(item => item.fullname.includes(search)).map(el => <BookingItem el={el} markAsCame={markAsCame} remove={remove}/>)}
        </div>
    </div>
  )
}
