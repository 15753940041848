import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { faEnvelope, faFax, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './contact.module.css'
import { translate } from '../translate'
import { store } from '../../components/HOC/State'

export default function ContactInfo({handleClick}) {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

  return (
    <div className={styles['contact-us']}>
        <p className={styles['contact-category']}>{lang.tr_contacts}</p>
        <div className={styles['contact-part']}> 
            <div className={styles['flex-items']}>           
                <FontAwesomeIcon icon={faLocationDot} />
                <p>{lang.tr_address}</p>
            </div>
            <Link to='/contacts' onClick={()=> handleClick(40.2000050,44.4912290)} className={styles['link-item']}>
                <p>{lang.tr_address2}</p>
            </Link>
        </div>
        <div className={styles['contact-part']}> 
            <div className={styles['flex-items']}>
                <FontAwesomeIcon icon={faPhone} /> 
                <p>{lang.tr_tel}</p>
            </div>
            <p>+37415 27 27 27</p>
        </div>
        <div className={styles['contact-part']}>
            <div className={styles['flex-items']}>
                <FontAwesomeIcon icon={faFax} />
                <p>{lang.tr_fax}</p>
            </div>
            
            <p>+37415 27 27 27</p>
        </div>
        <div className={styles['contact-part']}>
            <div className={styles['flex-items']}>
                <FontAwesomeIcon icon={faEnvelope} /> 
                <p>{lang.tr_mail}</p>
            </div>
            <p>info@mergelyan.com</p>
        </div>
  </div>
  )
}
