import React, { useContext, useEffect, useState } from 'react'
import ExpoItem from './ExpoItem'
import styles from './expo.module.css'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'
import { translate } from '../translate'

export default function Soon({from}) {
  const [arr, setArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [updateed, setUpdated] = useState(false)
  const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)

  let lang = translate[selectedLang]
  const limit = 20

  useEffect(() => {
    setOffset(0)
    setUpdated(true)
  }, [])

  useEffect(() =>{
    (async() => {
      if(updateed){
        setLoading(true)
        const res = await axiosInstance.get(`/use/expo/future?offset=${offset}&limit=${limit}`)
        if(res.data.status){
          setArr([...arr,...res.data.data])
          if(res.data.data.length == 20){
            setOffsetUpdated(false)
          }
        }else{
          setArr([...arr])
        }
        setLoading(false)
      }
    })()
  }, [offset, updateed])

  return (
    <div className={styles['status-main']}> 
        <div className={styles['status-size']}>    
            <div className={styles['status-list']}>
              {arr.length ? 
                <div>
                  {arr.map((el) => (
                      <ExpoItem el={el} key={el._id} from={from}/>
                  ))}
                  {loading ? <Loading width={200}/> : ""}
                </div>
                :
                !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null
              }
              
            </div>
        </div>  
    </div>
  )
}
