import React, { useContext } from 'react'
import styles from './news.module.css'
import { store } from './HOC/State'
import { Link } from 'react-router-dom'

export default function NewsItem({el}) {
  const  {selectedLang} = useContext(store)
  return (
    <Link to={`/news/${el._id}`}>
      <div className={styles['news-item-div']}>
          <p className={styles['news-item-name']}>{el[`${selectedLang}name`]}</p>
          <p className={styles['news-item-date']}>{new Date(el.created_at).toLocaleDateString()}</p>
      </div>
    </Link>
  )
}
