import React from 'react'
import SideNav from './SideNav'
import styles from './mainAdmin.module.css'
import { Outlet } from 'react-router'

export default function MainAdmin() {
  return (
    <div className={styles['main-admin']}>
        <SideNav/>
        <Outlet/>
    </div>
  )
}
