import React, { useState } from 'react'
import styles from './museumBook.module.css'

export default function NavbarMuseum({part, setPart}) {
  return (
    <div className={styles['nav-div']}>
        <div className={part === 'booking' ? styles.active : ""} onClick={() => setPart("booking")}>Ամրագրումներ</div>
        <div className={part === 'about' ? styles.active : ""} onClick={() => setPart('about')}>Թանգարանի մասին</div>
    </div>
  )
}
