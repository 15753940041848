import Main from './Main'
import styles from './App.module.css'
import State from './components/HOC/State';

function App() {
  return (
    <div className={styles["main-div"]}>
        <State>
            <Main/>
        </State>
    </div>
  );
}

export default App;
