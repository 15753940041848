import React, { useEffect, useState } from 'react'
import styles from './mainAdmin.module.css'
import CustomCart from '../../../components/HOC/CustomCart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { axiosInstance } from '../../../const/axiosInstance'

export default function Messages() {
    let [arr, setArr] = useState([])

    useEffect(() => {
        (async () => {
            const res = await axiosInstance.get(`/admin/requests`)
            if(res.data.status){
                setArr(res.data.data)
            }else{
                setArr([])
            }
        }
        )()
    }, [])

    const markAsRead = async (id) => {
        await axiosInstance.post(`/admin/request`, {id})
        setArr(prev => prev.map(el => el._id == id ? {...el,read:true} : el))
    }

    const remove = async (id) => {
        await axiosInstance.delete(`/admin/request/${id}`)
        setArr(prev => prev.filter(item => item._id != id))
    }

  return (
    <div className={styles['messages-list']}>
        <h2>Հայտեր</h2>
        {arr.map(el => 
            <div className={el.read ? `${styles['message-div']} ${styles['read']}` : styles['message-div']} key={el._id}>
                <div className={styles['message-from']}>
                    <p>Անուն։ {el.name}</p>
                    <p>էլ․֊հասցե։ {el.email}</p>
                </div>
                <div className={styles['message']}>{el.message}</div>
                <button className={styles['icons']}>
                   {!el.read ? <div><FontAwesomeIcon icon={faCheck} className={styles['check']} onClick={() => markAsRead(el._id)}/> <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el._id)}/></div>: <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el._id)}/>}
                </button> 
            </div>)
        }
    </div>
  )
}
