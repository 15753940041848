import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Parser } from 'html-to-react'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './newsOrDec.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function NewsOrDecPage({children, category}) {
    const [el, setEl] = useState({})
    const [loading, setLoading] = useState(false)

    const  {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const htmlParser = new Parser()

    const {id} = useParams()

    useEffect(() =>{
        (async() => {
            setLoading(true)
            const res = await axiosInstance.get(`/use/${category}/${id}`)
            if(res.data.status){
              setEl(res.data.data)
            }else{
              setEl({})
            }
            setLoading(false)
        })()
      }, [])

  return (
      <div className={styles['news-declaration-page-main']}>
        {loading ? <Loading width={200}/> :
        <div className={styles['news-declaration-page-info']}>
            <p className={styles['news-declaration-category']}>{category !== "news" ? lang.tr_announcements : lang.tr_news}</p>
            <h2 className={styles['news-declaration-page-title']}>{el[`${selectedLang}name`]}</h2>
            <div className={styles['news-declaration-page-created']}>
                <FontAwesomeIcon icon={faCalendarDays} />
                <p>{new Date(el.created_at).toLocaleDateString()}</p>
            </div>
            {htmlParser.parse(el[`${selectedLang}information`])}
        </div>}
        {children}
      </div>
  )
}
