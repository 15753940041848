import React from 'react'
import styles from './customCart.module.css'

export default function CustomCart({children}) {
  return (
    <div className={styles['cart-div']}>
        {children}
    </div>
  )
}
