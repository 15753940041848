import { Parser } from 'html-to-react'
import React, { useEffect, useState } from 'react'
import styles from './museumBook.module.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { axiosInstance } from '../../../const/axiosInstance'

export default function AboutMuseum() {
    const [about, setAbout] = useState({})

    const htmlParser = new Parser()

    useEffect(() => {
        (async () => {
            const res = await axiosInstance.get(`/use/about-museum`)
                if(res.data.status){
                    setAbout(res.data.data)
                }else{
                    setAbout('')
                }
        })()
    }, [])

  return (
    <div className={styles['about-div']}>
        {htmlParser.parse(about.armtext)}
        <div className={styles['icons']}>
            <Link to='about-edit'><FontAwesomeIcon icon={faPenToSquare} className={styles['edit']}/></Link>
        </div>
    </div>
  )
}
