import React, { useContext, useEffect, useState } from 'react'
import { faEnvelopeOpen, faLocationDot, faSquarePhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RentPromo from './RentPromo'
import RentPlaces from './RentPlaces'
import styles from './rent.module.css'
import stylesContact from '../Museum/museum.module.css'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'
import { translate } from '../translate'

export default function Rent() {
    const [arr, setArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateed, setUpdated] = useState(false)
    const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)

    let lang = translate[selectedLang]
    const limit = 20

    useEffect(() => {
      setOffset(0)
      setUpdated(true)
    }, [])

    useEffect(() =>{
      (async() => {
        if(updateed){
          setLoading(true)
          const res = await axiosInstance.get(`/use/rent?offset=${offset}&limit=${limit}`)
          if(res.data.status){
            setArr([...arr,...res.data.data])
            if(res.data.data.length == 20){
              setOffsetUpdated(false)
            }
          }else{
            setArr([...arr])
          }
          setLoading(false)
        }
      })()
    }, [offset, updateed])

  return (
    <div className={styles['rent-main']}>
        <div >
            <RentPromo/>
        </div>
        <div className={stylesContact['contact-div']}>
            <div className={stylesContact['contact-part-div']}>
                <FontAwesomeIcon className={stylesContact['contact-part-icon']} icon={faSquarePhone} />
                <p className={stylesContact['contact-part-title']}>{lang.tr_telephone}</p>
                <p>+37415 27 27 27</p>
            </div>
            <div className={stylesContact['contact-part-div']}>
                <FontAwesomeIcon className={stylesContact['contact-part-icon']} icon={faEnvelopeOpen} />
                <p className={stylesContact['contact-part-title']}>{lang.tr_mail}</p>
                <a href='mailto: info@mergelyan.com'>info@mergelyan.com</a>
            </div>
            <div className={stylesContact['contact-part-div']}>
                <FontAwesomeIcon className={stylesContact['contact-part-icon']} icon={faLocationDot} />
                <p className={stylesContact['contact-part-title']}>{lang.tr_address}</p>
                <p>{lang.tr_address2}</p>
            </div>
        </div>
        <div className={styles['rent-places-list']}>
          {arr.length ? (
            arr.map(el => <RentPlaces key={el._id} el={el}/>)
          ) : (
           !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null  
          )}
            {loading ? <Loading width={200}/> : ""}
        </div>
    </div>
  )
}
