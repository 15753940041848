import React, { useState } from 'react'
import Map from './Map';
import ContactInfo from './ContactInfo';
import OnlineReport from './OnlineReport';
import styles from './contact.module.css'

export default function Contact({children}) {
    const [location, setLocation] =useState(null);

    const handleClick = () => {
      setLocation({lat: "40.1995326",lng: "44.4906087"})
    }
  return (
    <div className={styles['contact-main']}>
        <div>
            <div className={styles['contact-info']}>
                <Map location={location}/>
                <ContactInfo  handleClick={handleClick}/>
            </div>
            <div className={styles['online-contact']}>
                <OnlineReport/>
            </div>
        </div>
        {children}
    </div>
  )
}
