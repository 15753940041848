import React, { useContext, useEffect, useState } from 'react'
import { Parser } from 'html-to-react'
import styles from './history.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'
import Loading from '../components/Loading'
import { axiosInstance } from '../const/axiosInstance'

export default function History({children}) {
  const [history, setHistory] = useState('')
  const [loading, setLoading] = useState(false)
  
  const {selectedLang} = useContext(store)
  let lang = translate[selectedLang]

  const htmlParser = new Parser()

  useEffect(() =>{
    (async() => {
        setLoading(true)
        const res = await axiosInstance.get(`/use/history`)
        if(res.data.status){
          setHistory(res.data.data)
        }else{
          setHistory("")
        }
        setLoading(false)
    })()
  }, [])
  
  return (
    <div className={styles['history-div']}>
        <div className={styles['history-info']}>
            <div className={styles['children']}>
                <p className={styles['history-category']}>{lang.tr_history}</p>
                {loading ? <Loading width={200}/> :
                <div>
                    {/* <Loading width={200}/> */}
                    {htmlParser.parse(history[`${selectedLang}text`])}
                </div>}
            </div>
            {children}
        </div>
    </div>
  )
}
