import React from 'react'
import { Link } from 'react-router-dom'
import styles from './rent.module.css'
import { BACKEND_URL } from '../../const/Constant'

export default function RentPlaces({el}) {
  return (
    <Link to={`/rent/${el._id}`}>
      <div className={styles['rent-item']}>
          <img src={BACKEND_URL + el.images[0]} alt='element-image' className={styles['rent-item-image']}/>
          <p className={styles['rent-title']}>{el.area} քմ․</p>
      </div>
    </Link>
  )
}
