import React, { useContext, useState } from 'react'
import styles from './imagesLibrary.module.css'
import CustomModal from '../../components/Modal/CustomModal'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'
import { Parser } from 'html-to-react'

export default function ImagesItem({el}) {
    const [showDescription, setShowDescription] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const  {selectedLang} = useContext(store)

    const htmlParser = new Parser()

    const enter = () => {
        setShowDescription(true)
    }

    const leave = () => {
        setShowDescription(false)
    }

    const open = () => {
        setShowModal(true)
    }

    const close = () => {
        setShowModal(false)
    }
 
  return (
    <div className={styles['each-image-div']} onMouseEnter={enter} onMouseLeave={leave} onClick={open}>
        <div className={styles['image-descr-div']}>
            <img src={BACKEND_URL + el.image} alt='mergelyan-image'/>
            <div className={styles['image-descr-p']}>
                {showDescription && <p>{htmlParser.parse(el[`${selectedLang}information`])}</p>}
            </div>
        </div>
        {showModal && <CustomModal close={close}><img src={el.path} alt='mergelyan-image'/></CustomModal>}
    </div>
  )
}
