import React from 'react'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './mainAdmin.module.css'

export default function SearchAd({search, changeSearch}) {
  return (
    <div className={styles['search-div']}>
        <input type='text' className={styles['search-input']} value={search} onChange={e => changeSearch(e)} />
        <FontAwesomeIcon className={styles['search-icon']} icon={faMagnifyingGlass} />
    </div>
  )
}
