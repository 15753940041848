import React, { useEffect } from 'react'
import { Icon } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import styles from './contact.module.css'
import  'leaflet/dist/leaflet.css'

const UpdateMapView = ({location}) => {
    const map = useMap();
  
    useEffect(() => {
      if(location){
        map.setView([location.lat, location.lng], map.getZoom());
      }
    }, [location,map])
  
    return null;
  }

export default function Map({location}) {
    const markers =[
        {
            geocode:  [40.2000050,44.4912290],
            popUp: "Mergelyan" , 
            id:1
        }
      
    ]
     const icon = new Icon({
           iconUrl: "/location.png",
           iconSize : [40,40]
        })
  return (
    <div className={styles['map-main']}>
        <style>{
                `.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
                        display:none
                }
                        
                .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
                        z-index: 0
                }
                `
                }</style>
        <MapContainer center={[40.1995326,44.4906087]} zoom={15} className={styles['map']}>
             <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
            {markers.map((marker, index) => (
                <Marker key={marker.id || index} position={marker.geocode} icon={icon}>
                    <Popup>
                        <h2>Մերգելյան</h2>
                    </Popup>
                </Marker>
            ))
            } 
            <UpdateMapView location={location}/>
        </MapContainer>
    </div>
  )
}
