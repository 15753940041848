import React, { useContext, useState } from 'react'
import CustomModal from '../../components/Modal/CustomModal'
import styles from './expo.module.css'
import { BACKEND_URL } from '../../const/Constant'

export default function ExpoLibrary({el}) {
    const [showModal, setShowModal] = useState(false)

    const open = () => {
        setShowModal(true)
    }

    const close = () => {
        setShowModal(false)
    }

  return (
    <div className={styles['each-image-div']} onClick={open}>
        <div className={styles['image-div']}>
            <img src={BACKEND_URL + el.image} alt='expo-image'/>
        </div>
        {showModal && <CustomModal close={close}><img src={BACKEND_URL + el.image} alt='expo-image'/></CustomModal>}
    </div>
  )
}
