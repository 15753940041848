import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import styles from './newsOrDec.module.css'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'

export default function NewsOrDeclarItem({el, category}) {
  const  {selectedLang} = useContext(store)

  const id = el._id

  return (
    <Link to={category !== "news" ? `/declaration/${id}` : `/news/${id}`}>
      <div className={category !== "news" ? styles['declaration-item'] : styles['news-item']}>
          <img src={BACKEND_URL + el.image} alt='element-image' className={category !== "news" ? styles['declaration-image'] : styles['news-image']}/>
          <p className={styles['news-declaration-title']}>{el[`${selectedLang}name`]}</p>
          <div className={styles['news-declaration-date']}>
              <FontAwesomeIcon icon={faCalendarDays} />
              <p>{new Date(el.created_at).toLocaleDateString()}</p>
          </div>
      </div>
    </Link>
  )
}
