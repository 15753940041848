import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import styles from './edit.module.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditNearest({add}) {
    const [image, setImage] = useState('')
    const [expo, setExpo] = useState({})

    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/expo/nearest`)
                if(res.data.status){
                    setExpo(res.data.data[0])
                    setImage(res.data.data[0].image)
                
                }
            }
            })()
    },[])


    useEffect(() => {
        setExpo(image)
    }, [image])


    const addImage = async () => {
      await axiosInstance.post(`/admin/expo/nearest/add`, {image})
      navigate("/dashboard/expo")
    }

    const editImage = async () => {
        await axiosInstance.post(`/admin/expo/nearest/edit`, {id, image})
        navigate("/dashboard/expo")
      }

  return (
    <div className={styles['edit-part']}>
         <div className={styles['upload']}>
            <p>Ավելացնել նկար։ </p>
            <FileUpload setImage={setImage}/>  
            <input disabled={true} placeholder='ֆայլ' value={image}/>
        </div>
        <div className={styles['btn-div']}>
          {image ?
            <Link><button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addImage : editImage}>Հաստատել</button></Link>
            : <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
          }
            <Link to='/dashboard/expo'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}