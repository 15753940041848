import React from 'react'
import styles from './customModal.module.css'

export default function CustomModal({children, close}) {
  return (
    <div className={styles['custom-modal-div']} onClick={e => {e.stopPropagation(); close()}}>
        <div className={styles['custom-modal-content']} onClick={e => e.stopPropagation()}>
            {children}
        </div>
    </div>
  )
}
