import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import FileUpload from './FileUpload'
import styles from './edit.module.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function AddImage() {
    const [image, setImage] = useState('')
    const navigate = useNavigate()

    const addImage = async () => {
      await axiosInstance.post(`/admin/expo/library/add`, {image})
      navigate("/dashboard/expo")
    }

  return (
    <div className={styles['edit-part']}>
         <div className={styles['upload']}>
            <p>Ավելացնել նկար։ </p>
            <FileUpload setImage={setImage}/>  
            <input disabled={true} placeholder='ֆայլ' value={image}/>
        </div>
        <div className={styles['btn-div']}>
          {image ?
            <Link><button className={`${styles['btn']} ${styles['confirm']}`} onClick={addImage}>Հաստատել</button></Link>
            : <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
          }
            <Link to='/dashboard/expo'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}
