import React from 'react'
import NavbarMuseum from './NavbarMuseum'
import { useState } from 'react'
import AboutMuseum from './AboutMuseum'
import Bookings from './Bookings'

export default function MuseumAdmin() {
    const [part, setPart] = useState("booking")
  return (
    <div>
        <NavbarMuseum part={part} setPart={setPart}/>
        {part == 'booking' ? <Bookings/> : <AboutMuseum/>}
    </div>
  )
}
