import React, { useContext, useState } from 'react'
import styles from './contact.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import { axiosInstance } from '../../const/axiosInstance'

export default function OnlineReport() {
    const [name, setName] = useState('')
    const [email, setMail] = useState('')
    const [message, setMessage] = useState('')
    const [err, setErr] = useState('')

    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const inputChange = (e, callback) => {
        setErr('');
        callback(e.target.value)
      }

      const sendRequest = async () => {
        if(!name.trim() || !email.trim() || !message.trim()){
            setErr('Լրացրե՛ք բոլոր դաշտերը')
            return
        }else if(!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            setErr('Անվավեր էլ․֊հասցե')
            return
        }
        await axiosInstance.post(`/use/request`, {name, email, message})
        setName("")
        setMail("")
        setMessage("")
      }


  return (
    <div className={styles['online-contact-form']}>
        <h3>{lang.tr_Apply}</h3>
        <input type='text' placeholder={lang.tr_name} value={name} onChange={ e => inputChange(e, setName)}/>
        <input type='text' placeholder={lang.tr_mail} value={email} onChange={ e => inputChange(e, setMail)}/>
        <textarea rows={8} placeholder={lang.tr_content} value={message} onChange={ e => inputChange(e, setMessage)}/>
        <button onClick={sendRequest}>{lang.tr_send}</button>
        <div className={styles['online-contact-error']}>{err}</div>
    </div>
  )
}
